<template>
  <div class="maxHeight">
    <div class="header">
      <div class="title flex">
        <img src="../../assets/img/user_d.png" alt="" width="28">
        <h2>企业管理</h2>
        <span class="num">共计：{{ page.total }}条记录</span>
      </div>
    </div>
    <div class="contentBody">
      <div class="flex tableHead">
        <el-button size="small" @click="add">新增企业</el-button>
        <el-input clearable v-model="search" placeholder="请输入公司名称" suffix-icon="el-icon-search"
                  @change="skipPage(1)"></el-input>
      </div>
      <el-table
          border
          :data="tableData"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 320px)">
        <el-table-column label="用户名" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.username ? scope.row.username.split('.')[1] : '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="companyCode" label="企业代码" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="companyName" label="公司名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="companyTax" label="公司税号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="officeAddr" label="办公地址" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="phone" label="联系电话" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="contact" label="联系人" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="mobile" label="联系人手机" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="开通日期" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.activeDate | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="presentBalance" label="赠送金额(元)" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="使用状态" width="120">
          <template slot-scope="scope">
            <el-button size="small" v-if="scope.row.useStatus==1" class="experimentCo">正常使用</el-button>
            <el-button size="small" v-else class="approval">余额不足</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="reset(scope.row.companyId)">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChange"
            @current-change="skipPage"
            :current-page="page.current"
            :page-size="page.size"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
      </div>
    </div>
    <!--    新增编辑-->
    <el-dialog  :close-on-click-modal="false"
        :title="action+'企业'"
        class="flex dialogForm "
        top="0"
        :visible.sync="dialogFormVisible"
        width="450px">
      <el-form ref="formAction" size="small" :model="formAction" class="addForm" label-suffix=":" :rules="ruleInline"
               label-width="100px">
        <el-form-item label="企业代码" prop="companyCode">
          <el-input v-model="formAction.companyCode" placeholder="请输入企业代码"></el-input>
        </el-form-item>
        <el-form-item label="用户名" :prop="action=='新增'?'username':''">
          <el-input v-model="formAction.username" :readonly="action=='编辑'?true:false" maxlength="16" minlength="4"
                    placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" :prop="baseStyles!='none'?'password':''" :style="{'display':baseStyles}">
          <el-input v-model="formAction.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="公司名称" prop="name">
          <el-input placeholder="请输入公司名称" maxlength="16" minlength="1" v-model="formAction.name">
          </el-input>
        </el-form-item>
        <el-form-item label="公司税号" prop="taxNo">
          <el-input v-model="formAction.taxNo" placeholder="请输入公司税号"></el-input>
        </el-form-item>
        <el-form-item label="办公地址" prop="officeAddr">
          <el-input placeholder="请输入办公地址" v-model="formAction.officeAddr" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input placeholder="请输入联系电话" v-model="formAction.phone" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact">
          <el-input placeholder="请输入联系人" maxlength="11" minlength="11" v-model="formAction.contact" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="联系人手机" prop="mobile">
          <el-input placeholder="请输入联系人手机" maxlength="11" minlength="11" v-model="formAction.mobile" autocomplete="off">
          </el-input>
        </el-form-item>
        <el-form-item label="赠送金额" prop="presentBalance">
          <el-input placeholder="请输入赠送金额" type="number" :min="1" :max="1000000" v-model="formAction.presentBalance"
                    autocomplete="off">
            <span slot="suffix">元</span>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="submit('formAction')">确认</el-button>
          <el-button size="small" type="primary" plain @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--重置密码-->
    <el-dialog  :close-on-click-modal="false"
               class="commonDialog flex" center
               top="0"
               :visible.sync="resetPwd"
               width="260px">
      <img src="../../assets/img/tips.png" alt="" width="52px">
      <h3>是否确认将该访客的密码重置为：{{ newPwd.password }} ?</h3>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="submitPwd">确认</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "enterprise",
  data() {
    let checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        callback(new Error('请输入电话号码'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    };
    return {
      search: '',
      tableData: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
      action: '新增',
      dialogFormVisible: false,
      formAction: {
        activeDate: "",
        companyCode: null,
        companyId: null,
        username: "",
        name: "",
        taxNo: "",
        contact: "",
        mobile: "",
        officeAddr: "",
        phone: "",
        presentBalance: null,
      },
      // 是否在编辑状态
      isEdit: false,
      // 显示密码
      baseStyles: 'none',
      // 验证
      ruleInline: {
        username: [
          {required: true, message: '用户名不能为空', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '公司名称不能为空', trigger: 'blur'}
        ],
        taxNo: [
          {required: true, message: '公司税号不能为空', trigger: 'blur'}
        ],
        officeAddr: [
          {required: true, message: '办公地址不能为空', trigger: 'blur'}
        ],
        phone: [
          {validator: checkPhone, trigger: 'blur'}
        ],
        contact: [
          {required: true, message: '联系人不能为空', trigger: 'blur'}
        ],
        mobile: [
          {validator: checkPhone, trigger: 'blur'}
        ],
        presentBalance: [
          {required: true, message: '赠送金额不能为空', trigger: 'blur'}
        ],
      },
      // 重置密码
      resetPwd: false,
      newPwd: {
        companyId: null,
        password: '123456'
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$postJson('/oauth/admin/ac-company/page',
          {
            size: this.page.size,
            page: this.page.current,
            key: this.search
          }).then(res => {
        if(res.data.success){
          this.tableData = res.data.data.records;
          this.page.current = res.data.data.current;
          this.page.total = res.data.data.total;
          this.page.size = res.data.data.size;
        }
      }).catch(err => {
        this.$message.error("获取数据失败")
      })
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getData();
    },
    // 新增
    add() {
      this.isEdit = false
      this.baseStyles = '';
      this.formAction = {}
      this.action = '新增'
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    // 编辑
    handleEdit(index, row) {
      this.baseStyles = 'none';
      this.isEdit = true;
      this.action = '编辑'
      this.formAction = {
        username: row.username,
        activeDate: row.activeDate,
        companyCode: row.companyCode,
        companyId: row.companyId,
        name: row.companyName,
        taxNo: row.companyTax,
        contact: row.contact,
        mobile: row.mobile,
        officeAddr: row.officeAddr,
        phone: row.phone,
        presentBalance: row.presentBalance,
      }
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    // 提交新增编辑
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let tit, url;
          if (!this.isEdit) {
            tit = "添加"
          } else {
            tit = "修改"
          }
          this.$postJson('/oauth/admin/ac-company/save', this.formAction).then(() => {
            this.$message.success(tit + "成功")
            this.dialogFormVisible = false
            this.getData();
          }).catch(err => {
            this.$message.error(err.response.data.message)
          })
        }
      })
    },
    // 重置密码弹框
    reset(id) {
      this.resetPwd = true
      this.newPwd.companyId = id
    },
    // 提交重置密码
    submitPwd() {
      this.$postJson('/oauth/admin/ac-company/resetPwd', this.newPwd).then(() => {
        this.$message.success("重置成功")
        this.getData();
        this.resetPwd = !this.resetPwd;
      }).catch((err) => {
        this.$message.error(err)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/exper/experiment";
</style>
